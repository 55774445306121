@use '@angular/material' as mat;
@import '../../../../node_modules/@angular/material/theming';
@include mat.core();

// standaard light theme
$light_theme_primary: (
    50 : #e0efef,
    100 : #b3d7d7,
    200 : #80bdbd,
    300 : #4da2a2,
    400 : #268e8e,
    500 : #007a7a,
    600 : #007272,
    700 : #006767,
    800 : #005d5d,
    900 : #004a4a,
    A100 : #7effff,
    A200 : #4bffff,
    A400 : #18ffff,
    A700 : #00fdfd,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//Dark-theme primary
$dark_theme_primary : (
    50 : #e3e3e3,
    100 : #b8b8b8,
    200 : #898989,
    300 : #595959,
    400 : #363636,
    500 : #121212,
    600 : #101010,
    700 : #0d0d0d,
    800 : #0a0a0a,
    900 : #050505,
    A100 : #b3d7d7,
    A200 : #4da2a2,
    A400 : #268e8e,
    A700 : #007a7a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


$accent: (
    50 : #e0efef,
    100 : #b3d7d7,
    200 : #80bdbd,
    300 : #4da2a2,
    400 : #268e8e,
    500 : #007a7a,
    600 : #007272,
    700 : #006767,
    800 : #005d5d,
    900 : #000000,
    A100 : #268e8e,
    A200 : #268e8e,
    A400 : #268e8e,
    A700 : #268e8e,
    contrast: (
        50 : #007a7a,
        100 : #007272,
        200 : #006767,
        300 : #005d5d,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #007a7a,
        A200 : #007272,
        A400 : #006767,
        A700 : #005d5d,
    )
);

$accent_dark: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//Available color palettes: https://material.io/design/color/
$bc-app-primary:  mat.define-palette($light_theme_primary);
$bc-app-accent:  mat.define-palette($accent, 400, 600, 700);
$bc-app-warn:  mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$bc-app-theme: mat.define-light-theme($bc-app-primary, $bc-app-accent, $bc-app-warn);


//Define an alternate dark theme.
$dark-primary: mat.define-palette($dark_theme_primary);
$dark-accent:  mat.define-palette($accent_dark, 400, 600, 700);
$dark-warn:    mat.define-palette(mat.$red-palette);
$dark-theme:   mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);


/** ALS JE CUSTOM CSS CLASSES WILT TOEVOEGEN EN TOCH DE JUISTE THEMING WIL GEBRUIKEN VOEG HEM HIER TOE, DOOR DE @INCLUDE IN DE .light-theme en .dark-theme CLASS WORD DIT MEEGENOMEN */
@mixin custom-overlay-style-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .mat-step-icon-state-number.mat-step-icon {
        background-color: mat.get-color-from-palette($primary) !important;
        color: mat.get-color-from-palette($primary, default-contrast) !important;
    }
    .mat-step-icon-state-number.mat-step-icon.mat-step-icon-selected{
        background-color: mat.get-color-from-palette($primary, default-contrast) !important;
        color: mat.get-color-from-palette($primary) !important;
    }

    .mat-chip.mat-standard-chip {
        background-color: mat.get-color-from-palette($primary) !important;
        color: mat.get-color-from-palette($primary, default-contrast) !important;
        border: 1px solid mat.get-color-from-palette($primary, default-contrast) !important;
    }

    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary{
        background-color: mat.get-color-from-palette($accent) !important;
        color: mat.get-color-from-palette($primary) !important;
    }

    .mat-expansion-panel-header{
        color: mat.get-color-from-palette($accent, 900, 0.7) !important;
    }
    .mat-expansion-panel-header-description {
        color: mat.get-color-from-palette($accent, 900, 0.7) !important;
    }

    .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
        background-color: mat.get-color-from-palette($primary) !important;
    }

    /**BOOTSTRAP*/

    // geolocator
    .form-control:focus {
        border-color: mat.get-color-from-palette($accent) !important;
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
    }

    // geolocator
    .form-control {
        border: 1.5px solid !important;
        border-radius: 4px !important;
        border-color: mat.get-color-from-palette($accent) !important;
        // box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
    }

    //odd row grid
    .ui-widget-content.slick-row.odd {
        background-color: mat.get-color-from-palette($accent, 300, 0.1)  !important;
    }

    /**END BOOTSTRAP*/

    merkator-layer-control .mat-expansion-panel-header-description{
        color: mat.get-color-from-palette($accent, 900, 0.7)  !important;
    }

    .mat-standard-chip:focus::after {
        opacity: 0 !important;
     }

    .buttonactiveaddreference{
        background-color: mat.get-color-from-palette($accent, default) !important;
        color: mat.get-color-from-palette($primary, default) !important;
    }

    .extra-class-snackbar {
        background-color: mat.get-color-from-palette($primary, default) !important;
        color: mat.get-color-from-palette($accent, default) !important;
        opacity: 1 !important;
        font-weight: 500 !important;
    }

    .extra-class-snackbar-error {
        background-color: mat.get-color-from-palette($primary, default) !important;
        color: mat.get-color-from-palette($warn, default) !important;
        opacity: 1 !important;
        font-weight: 500 !important;
    }

    .theme-background-color {
        background-color: mat.get-color-from-palette($accent, default) !important;
        opacity: 1 !important;
    }

    .theme-text-color {
        color: mat.get-color-from-palette($accent, default) !important;
        opacity: 1 !important;
    }

    mat-toolbar-row {
        border-bottom: 2px solid mat.get-color-from-palette($accent, default);
        z-index: 10;
    }

    .activebutton{
        background-color: mat.get-color-from-palette($accent, default) !important;
        color: mat.get-color-from-palette($primary, default) !important;
    }

    .merkator_map_tooltip.merkator_map_tooltip-static{
        
        color: mat.get-color-from-palette($primary, default) !important;
    }

    .merkator_map_tooltip.merkator_map_tooltip-measure{

        color: black !important;
    }

}

//Themes
.light-theme{
    @include mat.all-component-themes($bc-app-theme);
    @include custom-overlay-style-theme($bc-app-theme);
    //color: map-get($bc-app-primary,default-contrast);
    $primary-color: map-get($bc-app-primary,default);
    $grid-cell-color: map-get($bc-app-primary,default);
    $cell-even-background-color: $grid-cell-color;
    $focus-color: map-get($bc-app-primary,dark);
}

.dark-theme {
    @include mat.all-component-themes($dark-theme);
    @include custom-overlay-style-theme($dark-theme);
    //color: map-get($dark-primary,default-contrast);
    $primary-color: map-get($dark-primary,default);
    $grid-cell-color: map-get($dark-primary,default);
    $cell-even-background-color: map-get($dark-primary,lighter);
}
