
/*// Custom Theming for Angular Material
@use '~@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$viewer-client-primary: mat.define-palette(mat.$indigo-palette);
$viewer-client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$viewer-client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$viewer-client-theme: mat.define-light-theme($viewer-client-primary, $viewer-client-accent, $viewer-client-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($viewer-client-theme);

/* You can add global styles to this file, and also import other style files */
@import '@angular/material/theming';
@import "./themes/viewer_theme.scss";
//@import "@fortawesome/fontawesome-free/css/all.css";

/*
$primary-color: mat.define-palette($bc-app-primary, default);
$accent-color: mat.define-palette($bc-app-accent, default);
*/
html, body { height: 100%; width: 100%; }
body { margin: 0; padding: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.light-theme .mat-form-field-label { 
  color: rgba(0, 0, 0, 0.9) !important;
}
.light-theme .mat-input-element:disabled, .light-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.9) !important;
}

.merkator-map-coordinate-scale-frame-class {
  bottom: 87% !important;
}

@media (min-height: 400px) {
  .merkator-map-coordinate-scale-frame-class {
    bottom: 77% !important;
  }
}

@media (min-height: 500px) {
  .merkator-map-coordinate-scale-frame-class {
    bottom: 85% !important;
  }
}

@media (min-height: 700px) {
  .merkator-map-coordinate-scale-frame-class {
    bottom: 88% !important;
  }
}

@media (min-height: 900px) {
  .merkator-map-coordinate-scale-frame-class {
    bottom: 91% !important;
  }
  
}


.dropzone{
    width: 80%;
    height: 230px;
    padding: 2rem;
    text-align: center;
    border: dashed 1px #979797;
    position: relative;
    margin: 0 auto;

    input {
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        top: 0;
        left: 0;
    }

    label {
        color: white;
        width: 183px;
        height: 44px;
        border-radius: 21.5px;
        background-color: #007A7A;
        padding: 8px 16px;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        color: #38424c;
    }
}


.dropzonetemplates{
    margin-top: 50px;
    width: 80%;
    height: 230px;
    max-height: 230px;
    padding: 10px;
    text-align: left;
    border: dashed 1px #979797;
    position: relative;
    margin: 0 auto;
    overflow-y: auto;

    input {
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        top: 0;
        left: 0;
    }

    label {
        color: white;
        width: 183px;
        height: 44px;
        border-radius: 21.5px;
        background-color: #007A7A;
        padding: 8px 16px;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        color: #38424c;
    }
}


.fileover {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }
  
  .files-list {
    .single-file {
      position: relative;
      display: flex;
      padding: 0.5rem;
      justify-content: space-between;
      align-items: center;
      border: dashed 1px #979797;
      margin-bottom: 1rem;
      
      img.delete {
        margin-left: 0.5rem;
        cursor: pointer;
        align-self: flex-end;
      }
  
  
      display: flex;
      flex-grow: 1;
  
      .name {
        font-size: 14px;
        font-weight: 500;
        color: #353f4a;
        margin: 0;
      }
  
      .size {
        font-size: 12px;
        font-weight: 500;
        color: #a4a4a4;
        margin: 0;
        margin-bottom: 0.25rem;
      }
  
      .info {
        padding: 2px;
        width: 100%
      }
    }
  }
  
  /* Shake animation */
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
  
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
  
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
  
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
  
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
  
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
  
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
  
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
  
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
  
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
  
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }



/* END MERKATOR TAB STYLES */


#flexcontainer{
    margin-top: 20px !important;
}

mat-slide-toggle:focus { outline: none !important; };

.ui-droppable.ui-sortable {
    padding: 2px !important;
    margin-top: 10px !important;
}

.mat-drawer:not(.mat-drawer-side){
    box-shadow: none !important;
}

.mat-card-header {
  padding-top: 16px !important;
}

.mat-card-title {
  padding-top: 16px !important;
}

.mat-card {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}


.mat-slide-toggle-ripple.mat-ripple {
    opacity: 0 !important;
}
/*
.slick-placeholder {
    height: 20px !important;
}

.slick-preheader-panel.ui-state-default {
    margin-top: 10px !important;
    margin: 5px !important;
    height: auto !important;
}*/

.mat-button-focus-overlay {
    background-color: transparent!important;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

mat-sidenav-container {
    max-height: calc(100% - 64px);
    height: calc(100% - 64px);
}

.bottom-sheet-container{
    min-width: 100vw !important;
    padding: 0px !important;   
    max-height: calc(100% - 64px) !important; 
}

.mat-bottom-sheet-container {
    overflow: hidden !important;
    min-width: 100vw !important;
    padding: 0px !important;
}

.cdk-global-scrollblock {
    position: static;
    overflow-y: hidden;
}

.mat-icon-button .mat-button-wrapper {
    display: flex;
    justify-content: center;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: lightgray; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: gray; 
}

.pac-container:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

    background-image: none !important;
    height: 0px;
}

.mat-tab-label.mat-ripple.mat-tab-disabled.ng-star-inserted {
    // margin-left: auto !important;
    // padding-right: 100px;
    opacity: 1 !important;
}

.row {
  display: flex;
}

.space-between-center {
  justify-content: space-between;
  align-items: center;
}


.mat-tab-labels :last-child.mat-tab-label {
  min-width: 0;
  padding: 0 12px;
  padding-right: 0 !important;
  margin-left: auto !important;
  color: unset;
  opacity: unset;
}

mat-list {
  flex-grow: 1;
  // border: 3px solid green;
  overflow: auto;
}